import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/s2.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT HOMETOWN FLOORS ONLINE</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
              3790 Harvester Road St. Peters, MO 63303       </p>
              <a href="https://www.google.com/maps/place/HomeTown+Floors/@38.7447451,-90.5825448,17z/data=!4m13!1m7!3m6!1s0x87df298d7443a9c7:0x7a3d4df489ebbfed!2s3790+Harvester+Rd,+St+Peters,+MO+63303!3b1!8m2!3d38.7447409!4d-90.5803508!3m4!1s0x87df29f091b4665f:0x485e039cc10eac78!8m2!3d38.744758!4d-90.5803912" target="_blank" class="btn btn-primary  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(636) 244-4951</p>
            </div>

            <div className="mb-4">
              <a href="tel:+16362444951" class="btn btn-primary  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
              Mon-Fri 9am - 6pm<br />
              Sat 10am - 4pm
              <br />
              Sun Closed
              </p>
            </div>

            <div>
              <a href="/free-estimate" class="btn btn-primary  btn-lg active d-flex justify-content-center" role="button" style={{  borderRadius: '15px' }} >Request an estimate</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5 flex items-center justify-center">
        <a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6223.653736074278!2d-90.580407!3d38.744735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87df29f091b4665f%3A0x485e039cc10eac78!2sHomeTown%20Floors!5e0!3m2!1sen!2sus!4v1714485291872!5m2!1sen!2sus" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
