import React, { useState } from 'react'
import logo from './home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div className='font-sans'>
			<div class="bg-[#171717] ">
			<div className="flex item-center  md:hidden justify-between pb-3 " >
							<a href="tel:+16362444951" className='text-white text-[20px] mt-2'> <i class="bi bi-telephone"></i> </a>
							<a href="/" class="d-block " ><img src={logo}  alt="Los Angeles" /> </a>
							<a href="https://www.google.com/maps/dir//HomeTown+Floors+3790+Harvester+Rd+St+Peters,+MO+63303/@38.744735,-90.5804066,16z/data=!4m5!4m4!1m0!1m2!1m1!1s0x87df29f091b4665f:0x485e039cc10eac78" className='text-white text-[20px] mt-2'> <i class="bi bi-map"></i> </a>


						</div>
					<div class="flex items-center  lg:order-2 pb-2 px-3">
					
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>
				<div class="container px-2">


					<div class="flex items-center justify-center  pb-2">

						<div class="hidden md:flex md:items-center">					</div>

						<div className=" hidden md:flex md:item-center justify-center " >
							<a href="/" class="d-block "  ><img src={logo} alt="Los Angeles"  /> </a>


						</div>
						<div
						className={`nav-links duration-500 md:static   text-white  pt-2  left-0 ${menuOpen ? 'top-[9%] bg-[#171717] relative' : 'top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `}
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  border-slate-700 font-sans">

							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-white   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
									<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							{/* <li>
								<a href="/specials" class="text-white   btn px-3">Specials</a>
							</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> Products</span>
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-green-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/glasstile" class=" dropdown-item hover:text-green-900 ">Glass-tile</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/waterproof_Flooring" class=" dropdown-item hover:text-green-900 ">Waterproof Flooring</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-green-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/Tile" class=" dropdown-item hover:text-green-900 ">Tile</a>
										</li>
										<li>
											<a href="/products/naturalstone" class=" dropdown-item hover:text-green-900 ">Natural Stone</a>
										</li>


									</ul>
								</div>
							</li>
							<li>
											<a href="/flooring-services" class=" text-white  btn px-3"> <span className='font-bold'> Services</span></a>
										</li>
							<li>
								<a href="/reviews" class="text-white btn px-3 font-bold"><span className='font-bold'> Reviews</span></a>
							</li>
							<li>
								<a href="/financing" class="text-white  btn px-3"><span className='font-bold'> Financing</span></a>
							</li>
							<li>
								<a href="/inspiration" class="text-white  btn px-3"><span className='font-bold'> Inspiration</span></a>
							</li>
							<li>
								<a href="/about-us" class="text-white  btn px-3"><span className='font-bold'> About Us</span></a>
							</li>
							<li>
								<a href="/contact" class="text-white  btn px-3"><span className='font-bold '> Contact</span></a>
							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="/free-estimate" class=" text-white  btn px-3"><span className='font-bold '>Request an Estimate</span></a>

							</li>


						</ul>
					</div>


						<div class="hidden  md:flex md:items-center pl-[30px]">
							<div className='flex flex-col mx-2'>
							<div className='flex mt-1 text-white'>ST. PETERS, MO</div>
							<div className='flex mt-1'><a href="tel:+16362444951" className='text-white '> <i class="bi bi-telephone"> {` `}(636) 244-4951</i> </a></div>
							</div>
							<div className='flex'><a href="/free-estimate" class=" text-white  btn btn-primary  px-3 py-2 "><span className='font-bold'>Shop At Home</span></a></div>
							
						</div>


					</div>
					



					
				</div>




			</div>


		</div>
	)
}

export default Header
