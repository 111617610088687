import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Productdetail() {
    const navigate = useNavigate();
    const { id, category } = useParams();
    const [imageData, setImageData] = useState([]);
    const [data1, setdata1] = useState([]);
    const [data2, setdata2] = useState([]);
    const [formData, setFormData] = useState({
        firstName: '',
        phoneNumber: '',
        email: '',
        zip: '',
        productlink: window.location.href,
        ProductName: '',
        sku: '',
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                let FormData = {
                    idd: id,
                    cat: category
                }

               

                const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage/id`, FormData);
                setFormData({ ...formData, sku: imageDataResponse.data.data1?.SKU, ProductName: imageDataResponse.data.data1?.Title });
                setImageData(imageDataResponse.data.data1);
                setdata1(imageDataResponse.data.data2);
                setdata2(imageDataResponse.data.data3);

            } catch (error) {
                console.error(error);
            } finally {
            }
        };

        fetchData();
    }, [category, id]);

    const handlepropertyselect = (id, category) => {
        navigate(`/products/${category}/${id}`);
        window.scrollTo(0, 0);
    }
    const [isModalOpen, setModalOpen] = useState(false);
    
    const handleSubmitQUOTE = async (event) => {
        event.preventDefault();
    
        try {
          
          const formresponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/form`, formData);
          alert(formresponse.data.data1);
          setModalOpen(false);
    
        } catch (error) {
          console.error('Error:', error);
        }
    };

    return (
        <div>
            <div><Headers /> </div>


            <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end container">
                <ol class="breadcrumb px-4">
                    <li class="breadcrumb-item">
                        <a href="/">Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <a href="/products">Product</a>
                    </li>
                </ol>
            </nav>



            <div class=" container card-box-c  p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
            <button
                        className="mb-2 px-4  text-slate-900 rounded-md hover:bg-green-700  hover:text-white focus:outline-none focus:ring focus:ring-green-400"
                        onClick={() => {
                            // Implement a navigation action or go back to a specific page
                            window.history.back();
                            window.scrollTo(0, 0);
                        }}
                    >
                        Go Back
                    </button>
                <div className="row">
                    
                    <div className="col-md-4">
                        <div class="img-box-a">
                            <img src={require('./Component/photo/' + id + '.png')} alt="ImageData" title={imageData.Image_Title} style={{ maxHeight: '400px', width: '400px' }} />
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div class="min-w-0 lg:col-span-3 md:mt-2 space-y-3">
                            <div>
                                <h1 class="!text-3xl !text-left">{imageData.Product_Line} - {imageData.Brand}</h1>
                                <div class="mt-2 flex items-center gap-2 text-sm text-gray-500 ">
                                    <span className="capitalize">{imageData.Type}</span> |<span>SKU# {imageData.SKU}</span></div></div><div>
                                <div class="mb-1 font-semibold text-sm text-gray-500">
                                    Color: <span class="font-normal text-black">{imageData.color}</span>
                                </div></div><div><div></div></div>
                            <div class="flex gap-2 flex-wrap !mt-8 p-3">
                                <div className='btn btn-primary w-30 cursor-pointer' onClick={() => { setModalOpen(true); }}>Request more info</div>
                            </div>
                            <ul class="flex flex-wrap gap-0.5 m-0 mt-2 p-0 list-none">
                                {data1.map((item) => (
                                    <li key={item.SKU_Id} >
                                        <button className="md:p-2 lg:p-2 p-1" onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                             <img src={require('./Component/photo/' + item.SKU + '.png')} alt="ImageData" title={item.color} style={{ height: '48px' }} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>


            </div>

            <div className="container p-5">
                <h3>
                    Specifications
                </h3>
                <table class="table table-striped">

                    <tbody>
                        {imageData.Brand && <tr>
                            <th scope="row" style={{ width: '10%' }}>Brand</th>
                            <td>{imageData.Brand}</td>
                        </tr>}
                        
                        {imageData.Product_Line && <tr>
                            <th scope="row" style={{ width: '10%' }}>Product Line</th>
                            <td>{imageData.Product_Line}</td>
                        </tr>}
                        {imageData.color && <tr>
                            <th scope="row" style={{ width: '10%' }}>Color</th>
                            <td>{imageData.color}</td>
                        </tr>}
                        {imageData.SKU && <tr>
                            <th scope="row" style={{ width: '10%' }}>SKU #</th>
                            <td>{imageData.SKU}</td>
                        </tr>}
                        {imageData.Thickneess && <tr>
                            <th scope="row" style={{ width: '10%' }}>Thickneess</th>
                            <td>{imageData.Thickneess}</td>
                        </tr>}
                        {imageData.Width && <tr>
                            <th scope="row" style={{ width: '10%' }}>Width</th>
                            <td>{imageData.Width}</td>
                        </tr>}
                        {imageData.length && <tr>
                            <th scope="row" style={{ width: '10%' }}>Length</th>
                            <td>{imageData.length}</td>
                        </tr>}
                        {imageData.Application && <tr>
                            <th scope="row" style={{ width: '10%' }}>Application</th>
                            <td>{imageData.Application}</td>
                        </tr>}
                        {imageData.Color_Tones && <tr>
                            <th scope="row" style={{ width: '10%' }}>Color Tones</th>
                            <td>{imageData.Color_Tones}</td>
                        </tr>}
                        {imageData.Material && <tr>
                            <th scope="row" style={{ width: '10%' }}>Material</th>
                            <td>{imageData.Material}</td>
                        </tr>}
                        {imageData.Surface_Type && <tr>
                            <th scope="row" style={{ width: '10%' }}>Surface Type</th>
                            <td>{imageData.Surface_Type}</td>
                        </tr>}
                        {imageData.Fiber_Brand && <tr>
                            <th scope="row" style={{ width: '10%' }}>Fiber Brand</th>
                            <td>{imageData.Fiber_Brand}</td>
                        </tr>}
                        {imageData.PatternRepeatWidth && <tr>
                            <th scope="row" style={{ width: '10%' }}>Pattern Repeat Width</th>
                            <td>{imageData.PatternRepeatWidth}</td>
                        </tr>}
                        {imageData.PatternRepeatLength && <tr>
                            <th scope="row" style={{ width: '10%' }}>Pattern Repeat Length</th>
                            <td>{imageData.PatternRepeatLength}</td>
                        </tr>}
                        {imageData.WidtFaceWeighth && <tr>
                            <th scope="row" style={{ width: '10%' }}>Width Face Weight</th>
                            <td>{imageData.WidtFaceWeighth}</td>
                        </tr>}
                        

                    </tbody>
                </table>
                <br></br>
                <h3>
                    Similar Products
                </h3>
                <div className="row mb-2 ">
                    {data2.map((item) => (
                        <div key={item.SKU} class=" col-md-3 p-2" id={item.Brand}>
                            <button onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                <div class="card-box-c  p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
                                    <div class="img-box-a">
                                         <img src={require('./Component/photo/' + item.SKU + '.png')} alt="ImageData" />
                                    </div>

                                    <div className="row" ><strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Brand} </strong> <br />
                                        <strong class="flex items-center text-sm font-semibold text-gray-700 ">{item.Product_Line}</strong><br></br>
                                        <strong class="flex items-center text-sm font-semibold text-gray-700 "> {item.color}</strong> </div>
                                    <div className='btn btn-primary w-75 '>See More colors</div>
                                </div>

                            </button>



                        </div>

                    ))}
                </div>
            </div>








            <div><Request /> </div>
            <div><Footer /> </div>
            {isModalOpen && (
                <div className="fixed inset-0 z-[010] flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="relative p-4 w-fit max-w-md ">
                        <div className="relative bg-white rounded-lg shadow w-fit">
                            <button
                                type="button"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                className="absolute top-3 right-2.5 hover:border  text-gray-400 bg-transparent hover:bg-gray-700 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-slate-700"
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <form className=" px-5 !bg-[#e8e6e6e0] bg-opacity-50" onSubmit={handleSubmitQUOTE} >
                                <div className='text-center font-bold pt-3'>
                                    <span className="text-blue-500 font-extrabold text-[27px]" style={{ fontSize: '27px' }} >LET'S GET STARTED
                                    </span> <br />
                                    <small> ON YOUR FREE QUOTE!</small>
                                </div>
                                <div className="rounded-md  flex flex-col  w-full item-center text-black justify-center">
                                    <div className="pt-3 pb-1">
                                        <input
                                            type="text"
                                            className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
                      rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
                      hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
                                            id="firstname"
                                            placeholder="Full Name"
                                            value={formData.firstName}
                                            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="py-1">
                                        <input
                                            type="number"
                                            className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
                                            id="phoneNumber"
                                            placeholder="Phone Number"
                                            maxLength={10}
                                            value={formData.phoneNumber}
                                            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="py-1">
                                        <input
                                            type="text"
                                            className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
                                            id="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="py-1">
                                        <input
                                            type="number"
                                            className="hover:!ring-4 w-[100%] shadow-sm shadow-gray-200 hover:!border-[1px] border !bg-white min-h-[40px] h-[40px] resize-y
          rounded-[8px] outline-none  p-2 disabled:bg-gray-300 text-sm placeholder:text-sm
          hover:!ring-blue-200  hover:!border-blue-500 focus:!ring-4 focus:!ring-blue-200 focus:!border-blue-500"
                                            id="address"
                                            placeholder="Zip Code"
                                            value={formData.zip}
                                            onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="py-4 text-[10px] font-semibold">
                                    By clicking GET QUOTE, I consent to communications from us via email and phone about sales and events. Consent isn't required for purchase. I can revoke it by calling or replying STOP. Calls are recorded. I agree to the policies.                                    </div>

                                </div>
                                <div className="py-4 flex items-center justify-center">
                                    <button
                                        type="submit"
                                        disabled={!(formData.phoneNumber.length === 10)}
                                        className="btn btn-primary    w-full  flex items-center justify-center text-white  rounded "
                                    >
                                        <span className="text-white font-bold text-[25px]"> GET QUOTE!</span>

                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Productdetail
