import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import about from './Component/about.png';
import brand1 from './Component/home/brand1.png';
import a1 from './Component/a1.jpg'
import a2 from './Component/a2.jpg'
import a3 from './Component/a3.jpg'
import a4 from './Component/a4.jpg'
import a5 from './Component/a5.jpg'
import a6 from './Component/a6.jpg'
import a7 from './Component/a7.jpg'
import a8 from './Component/a8.jpg'
import a9 from './Component/a9.jpg'
import a10 from './Component/a10.jpg'
import a11 from './Component/a11.jpg'
import a12 from './Component/a12.jpg'

function About() {
	return (
		<div>
			<div><Headers /> </div>
			<div className='container p-4'>
				<div className='text-center font-bold p-4'>
					<span style={{ fontSize: '35px' }}>ABOUT HOMETOWN FLOORS ONLINE</span> <br />
					<small className='justify'>GET TO KNOW YOUR SAINT PETERS AREA FLOORING EXPERTS</small>
				</div>
				<section className='container mb-4'>
					<div class="row" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<img src={about} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
						}}>
							<div className='jumbotron' >
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c">After over 60 years of combined personal flooring installation experience, three brothers founded HomeTown Floors in 2012. Bringing years of flooring expertise to make your purchasing, as well as your installation experience, as fast and trouble free as possible.</p>
												<p class="content-c">Maintaining the highest standards of quality, we utilize the latest technologies in flooring tools and equipment. We stay educated on the installation methods of the newest flooring products to hit the market. We take pride in our work and treat your home or business like ours. Our philosophy is to build a working relationship with our customers to achieve the look they desire at a cost that fits their budget.</p>
												<p class="lead">
													<a href="/free-estimate" class="btn btn-primary  btn-lg active" role="button" style={{ borderRadius: '8px' }}>Request an estimate</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
				</section >
			</div>
			<div className='text-center font-bold py-4 border-t border-slate-900 container'>
				<span style={{ fontSize: '35px' }}>OUR SHOWROOM HAS IT ALL</span> <br />
				<small className='justify'>YOU'LL WANT TO SEE IT FOR YOURSELF</small>
			</div>
			<div className='grid grid-cols-[50%_50%] gap-1 container'>
				<div className='flex flex-col'>
					<img
						src={a1}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a3}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a5}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a7}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a9}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a11}
						alt="brands"
						className="mx-auto flex "
					/>
				</div>
				<div className='flex flex-col'>
					<img
						src={a2}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a4}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a6}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a8}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a10}
						alt="brands"
						className="mx-auto flex "
					/>
					<img
						src={a12}
						alt="brands"
						className="mx-auto flex "
					/>
				</div>
			</div>


			<div><Request /></div>

			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
				</div>
				<section>
					<div >
						<img
							src={brand1}
							alt="brands"
							className="mx-auto "
						/>
					</div>

				</section>

			</section>
			<div><Footer /> </div>
		</div>
	)
}

export default About
