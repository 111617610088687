import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import s2 from './Component/s2.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4'>
        <span style={{ fontSize: '35px' }}>FAST AND EASY FINANCING</span> <br />
        <small className='justify'>SUBJECT TO CREDIT APPROVAL. MINIMUM MONTHLY PAYMENTS REQUIRED. SEE STORE FOR DETAILS.</small>
      </div>
      <section >
				<div class="container pt-3 flex item-center justify-center">
					<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

				</div>
        <div className='container flex text-center pt-3 font-semibold items-center justify-center'>6 MONTH 0% FINANCING OPTIONS AVAILABLE THROUGH SYNCHRONY</div>
        <div className='container flex text-center pt-2 items-center justify-center'>Whether it’s the perfect sale or simply the perfect timing for your home, you should be able to purchase your dream flooring when you want to.</div>
        <div className='container flex text-center pt-2 items-center justify-center'>However, you want to do so in a way that is convenient and won’t interfere with other household finances.

With the Mohawk Credit Card, we aim to provide you with the financial management tool to get your household exactly what you want when you want it. Now you can keep your cash and general credit cards freed up for family needs and emergencies.

We’ve partnered with trusted brands in finance like Synchrony Financial to offer our consumers the premium private label credit card program.</div>
        <div className='container flex text-center pt-4 items-center justify-center'>At Mohawk, we’re proud to offer the most comprehensive package of benefits available to our retailers, and finding your nearest participating Mohawk retailer is easier than ever.</div>
        <div className='container flex text-center pt-2 items-center justify-center'><a href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=0A96374331BD708109B284BEDF45A5396F5E0E04435551D3&client=Mohawk%20Carpet&intcmp=mohawk-flooring-internalapply" className='font-semibold text-blue-500 cursor-pointer '>You can apply online for your Mohawk Consumer Credit Card</a> or by visiting our showroom today. Upon approval, you will receive your card in the mail within 7 to 10 business days.</div>
        <div className='container flex text-center pt-2 items-center justify-center'>Your Mohawk Credit Card also comes with online access to your account at <a href="https://www.synchrony.com/marketplace" class="font-semibold text-blue-500 cursor-pointer">MySynchrony.com</a>, an easy way to manage your bill, schedule payment reminders and more.</div>
        <div className='container flex text-center pt-2 items-center justify-center'>The Mohawk Consumer Finance Program is designed with your needs and concerns in mind. Mohawk not only makes the room, we make it easier to finance!</div>
        <div className='container flex text-center item-center justify-center py-4 '><a href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=0A96374331BD708109B284BEDF45A5396F5E0E04435551D3&client=Mohawk%20Carpet&intcmp=mohawk-flooring-internalapply" > <buttton className=" btn btn-primary px-3 w-[250px] text-white text-center">Apply now</buttton> </a></div>
        
			</section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
