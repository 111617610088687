import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import home2 from './Component/s3.png';
import s2 from './Component/s2.png';

function About() {
	return (
		<div>
			<div><Headers /> </div>
			<div className='container'>
				<div className='text-center font-bold p-4'>
					<span style={{ fontSize: '35px' }}>Services</span> <br />
					<small className='justify'> SEE WHY WE’RE ST. CHARLES COUNTY & ST. CHARLES COUNTY, MO AREA'S MOST TRUSTED SERVICE PROVIDER</small>
				</div>
				<section >
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<img src={home2} alt="Los Angeles" class="d-block rounded" />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
							<ul className='jumbotron' >
								<li class="font-bold text-slate-500 py-2 ">New baseboard installation</li>
								<li class="font-bold text-slate-500 py-2 ">Flooring installation</li>
								<li class="font-bold text-slate-500 py-2 ">Design consultation</li>
								<li class="font-bold text-slate-500 py-2 ">Hardwood refinishing</li>
								<li class="font-bold text-slate-500 py-2 ">Custom area rugs</li>
								<li class="font-bold text-slate-500 py-2 ">Shop at home</li>
								<li class="font-bold text-slate-500 py-2 ">Mobile showroom</li>
								<li class="font-bold text-slate-500 py-2 ">Area rug binding</li>
								<li class="font-bold text-slate-500 py-2 ">Commercial flooring installation</li>
								<li class="font-bold text-slate-500 py-2 ">Carpet re-stretch</li>
								<li class="font-bold text-slate-500 py-2 ">Free in-home measurement</li>
								<li class="font-bold text-slate-500 py-2 ">Flooring repairs</li>
								<li class="font-bold text-slate-500 py-2 ">Furniture removal</li>
								<li class="font-bold text-slate-500 py-2 ">Subfloor repair</li>
								<li class="font-bold text-slate-500 py-2 ">Carpet disposal</li>
								<li class="font-bold text-slate-500 py-2 ">Custom showers & tub surrounds</li>


								<li class="">
									<a href="/free-estimate" class="btn btn-primary  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
								</li>
							</ul>

						</div>
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-5">
							<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>
						</div>
					</div>
				</section >
			</div>
			<div><Footer /> </div>
		</div>
	)
}

export default About
