import React, { useState } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from './Component/home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home2 from './Component//home/home1.jpg';
import home5 from './Component//home/home2.jpg';
import home3 from './Component//home/home3.jpg';
import brand1 from './Component/home/brand1.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import s2 from './Component/s2.png';
import hh1 from './Component/home1.png';
import special1 from './Component/special1.jpg';
import Header from './Component/Header';
import inspired from './Component/inspired.png';
import mainhome from './Component/mainhome.png';


function Home() {

	return (
		<div className='w-[100vw]' >

			<div className=""><Header /></div>
			<section >
				<img
					src={hh1}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto "
				/>

			</section>


			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl "> GET THE BEST FLOORING AT A GREAT PRICE IN ST.</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl md:px-16 xl:px-48 warning:text-gray-400"> CHARLES COUNTY, MO</p>
					<div class="row flex justify-content-center ">
						<div class="col-sm-6 col-lg-4 col-12 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${carpet})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Carpet</div></h2>

												<a href='/products/carpet' > <div className='btn btn-primary '>Browse Carpet</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Hardwood</div></h2>

												<a href='/products/hardwood' > <div className='btn btn-primary '>Browse Hardwood</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${laminate})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>luxuryVinyl</div></h2>

												<a href='/products/luxuryvinyl' > <div className='btn btn-primary '>Browse luxuryvinyl</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home2})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Laminate</div></h2>

												<a href='/products/laminate' > <div className='btn btn-primary '>Browse Laminate</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Tile</div></h2>

												<a href='/products/Tile' > <div className='btn btn-primary '>Browse Tile</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>

					</div>
					<a href='/products' className='flex items-center justify-center'> <div className='btn w-[25%] btn-light'> <span className='no-underline font-bold hover:text-slate-900'>View All Products </span></div> </a>

				</div>



			</section>
			
			<section >
				<div class="container pt-3 flex item-center justify-center">
					<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

				</div>
			</section>

			<div className='bg-[#323232]'>
				<section className='container'>
					<div class="row p-3" >
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
							<img src={home3} alt="Los Angeles" class="d-block" style={{ borderRadius: '5%' }} />
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center px-4" style={{
							paddingInline: '5% 10%'
						}}>
							<div className='jumbotron text-white' >
								<p class="h3 font-bold">WELCOME TO HOMETOWN FLOORS</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ padding: '20px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c ">After over 60 years of combined personal flooring installation experience, three brothers founded HomeTown Floors in 2012. Bringing years of flooring expertise to make your purchasing, as well as your installation experience, as fast and trouble free as possible.</p>
												<p class="content-c ">Maintaining the highest standards of quality, we utilize the latest technologies in flooring tools and equipment. We stay educated on the installation methods of the newest flooring products to hit the market.</p>
												<p class="lead">
													<a href="/about-us" class="btn btn-primary btn-lg active" role="button" style={{ borderRadius: '8px' }}>Explore More About Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
				</section >
			</div>
			<div className='bg-slate-200 my-2 flex flex-col items-center justify-center'>
			  <h1 class="flex text-center justify-center  text-2xl   leading-none tracking-tight text-gray-900 md:text-3xl lg:text-3xl pt-3 "> WHAT'S NEW WITH HOMETOWN FLOORS ONLINE?</h1>
				<div class=" flex items-center justify-center py-3 w-[400px] ml-[40px] sm:ml-[0px]">
					<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FHomeTownFloors%2F&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" title="example" width="100%" height="500" frameborder="0" className='flex items-center justify-center' />

				</div>
			</div>
			<section class="p-4 " style={{ backgroundColor: '#ffffff' }}>
				<div className='container'>
					<div class="row flex content-center">
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%', borderBlockColor: '#803333' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={inspired} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=""> Reviews</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									  The St. Charles County, MO area trusts Hometown Floors Online for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-primary w-100' style={{ borderRadius: '8px' }}> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 col-6 p-1 col-12" >
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={mainhome} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Request</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c  ">
									Our professional flooring installers are ready to help you with your next project.
									</p>
									<div class="mt-auto">
										<a href='/free-estimate' class='btn btn-primary w-100' style={{ borderRadius: '8px' }}>Schedule Now</a>
									</div>								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-6 p-1 col-12">
							<div class="card-box-c foo p-4 bg-white text-black" style={{ borderRadius: '15px', height: '100%' }}>
								<div class="card-header-c ">
									<div class="card-box-ico">
										<span ><i class="fa-sharp fa fa-house"><img src={special1} alt="Los Angeles" class="d-block rounded" /></i></span>
									</div>
									<div class="card-title-c">
										<h2 class=" "> Free Estimate</h2>
									</div>
								</div>
								<div class="card-body-c ">
									<p class="content-c ">
									  Request your accurate and professional in-home flooring estimate.
									</p>
									<a href='/free-estimate'> <div className='btn btn-primary w-100' style={{ borderRadius: '8px' }}> Schedule now</div> </a>
								</div>
							</div>

						</div>
					</div>
				</div>

			</section>
			<div className='bg-[#323232]'>
				<section className='container p-5'>
					<div class="row rounded">
						<div class="col-lg-4 col-md-12 mb-4 mb-lg-0  text-white rounded pr-4" >
							<p className='text-left ' style={{ paddingTop: '40px' }}> <h1>Get inspired</h1></p>
							<p className='text-left ' >We've got you covered flooring, carpet, hardwood, tile, LVP, Laminate, paints, painting supplies, stains, and blinds</p>
							<a href="/inspiration" className='btn btn-primary w-full p-2 text-white'> View our Galleries</a>
						</div>

						<div class="col-lg-8 mb-8 mb-lg-0">
							<img src={i1} alt="Los Angeles" class="d-block rounded " style={{ paddingTop: '40px' }} />


						</div>


					</div>
				</section>
			</div>
			
			
			
			
			
			<div><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>

				</div>
				<section>
					<div >
						<img
							src={brand1}
							alt="brands"
							className="mx-auto "
						/>
					</div>
				</section>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
